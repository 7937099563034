import { useState } from 'react';
import PropTypes from 'prop-types';
import { Validation } from 'calidation';
import { localizedString } from '@languages';
import { getResAddressValue } from '@lib/Utils';
import { getCountryLabelFromIso2Code, getStatesFromCountry } from '@lib/countryUtils';
import { Input, Select, CountrySelect } from '@lib/components/v2/Form';
import { detailed } from './AddressFinder.form';
import classes from './AddressFinder.style.module.scss';

export const ManualAddress = ({
  initialFieldsData = {},
  availableIso3Countries,
  onChange,
  disabled
}) => {
  const [selectedManualCountryCode, setSelectedManualCountryCode] = useState(
    initialFieldsData?.selectedManualCountryCode || 'AU'
  );

  const country = {
    value: selectedManualCountryCode,
    label: getCountryLabelFromIso2Code(selectedManualCountryCode)
  };

  const [detailedAddress, setDetailedAddress] = useState({
    ...initialFieldsData,
    country: country.label
  });

  const states = getStatesFromCountry(selectedManualCountryCode);

  const handleChangeAddress = (addressFields) => {
    const { addressLine1, postcode, suburb, state_territory: stateTerritory } = addressFields;

    const homeAddress = getResAddressValue({
      addressLine1,
      suburb,
      postcode,
      state_territory: stateTerritory
    });

    const data = {
      ...addressFields,
      city: suburb,
      homeAddress,
      fullAddress: homeAddress,
      isMatch: false,
      manual: true
    };

    onChange(data);

    setDetailedAddress(addressFields);
  };

  return (
    <Validation initialValues={detailedAddress} config={detailed}>
      {({ errors, setField }) => (
        <div className={classes.detailedWrapper}>
          <div className={classes.countries}>
            <CountrySelect
              filter={availableIso3Countries}
              value={country}
              onChange={({ value, label }) => {
                setSelectedManualCountryCode(value);

                const detailedAddr = { ...detailedAddress };
                detailedAddr.country = label;
                detailedAddr.selectedManualCountryCode = value;
                handleChangeAddress(detailedAddr);
                setField({ country: label });
              }}
            />
          </div>
          <Input
            type="text"
            id="addressLine1"
            placeholder={localizedString('addressLine1')}
            className={classes.input}
            hasError={errors.addressLine1}
            onChange={(value) => {
              const detailedAddr = { ...detailedAddress };
              detailedAddr.addressLine1 = value;
              handleChangeAddress(detailedAddr);
              setField({ addressLine1: value });
            }}
            value={detailedAddress.addressLine1 || ''}
            disabled={disabled}
          />
          <Input
            type="text"
            id="addressLine2"
            placeholder={localizedString('addressLine2')}
            className={classes.input}
            hasError={errors.addressLine2}
            onChange={(value) => {
              const detailedAddr = { ...detailedAddress };
              detailedAddr.addressLine2 = value;
              handleChangeAddress(detailedAddr);
              setField({ addressLine2: value });
            }}
            value={detailedAddress.addressLine2 || ''}
            disabled={disabled}
          />
          <Input
            type="text"
            id="suburb"
            placeholder={localizedString('suburbOrCity')}
            className={classes.input}
            hasError={errors.suburb}
            onChange={(value) => {
              const detailedAddr = { ...detailedAddress };
              detailedAddr.suburb = value;
              handleChangeAddress(detailedAddr);
              setField({ suburb: value });
            }}
            value={detailedAddress.suburb || ''}
            disabled={disabled}
          />
          <div className={classes.inputGroup}>
            <Input
              type="text"
              id="postcode"
              placeholder={localizedString('postcode')}
              autocomplete="none"
              hasError={errors.postcode}
              onChange={(value) => {
                const detailedAddr = { ...detailedAddress };
                detailedAddr.postcode = value;
                handleChangeAddress(detailedAddr);
                setField({ postcode: value });
              }}
              value={detailedAddress.postcode || ''}
              disabled={disabled}
            />
            {selectedManualCountryCode === 'AU' && (
              <div data-testid="manual_state_territory" style={{ position: 'relative' }}>
                <Select
                  id="state_territory"
                  borderBottomOnly
                  placeholder={localizedString('state')}
                  hasError={errors.state_territory}
                  options={states}
                  onChange={(state) => {
                    const detailedAddr = { ...detailedAddress };
                    detailedAddr.state_territory = state.value;
                    handleChangeAddress(detailedAddr);
                    setField({ state_territory: state.value });
                  }}
                  value={
                    detailedAddress.state_territory
                      ? {
                          label: detailedAddress.state_territory,
                          value: detailedAddress.state_territory
                        }
                      : null
                  }
                  disabled={disabled}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Validation>
  );
};

ManualAddress.propTypes = {
  initialFieldsData: PropTypes.object,
  availableIso3Countries: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};
