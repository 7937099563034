import InforAction from '../actions/information';

const initialState = {
  extracted: null,
  lastConfirmedDetails: null,
  otherDetails: []
};

export default function information(state = initialState, action = {}) {
  switch (action.type) {
    case InforAction.SET_EXTRACTED_INFO:
      return {
        ...state,
        extracted: {
          ...action.data
        }
      };

    case InforAction.SET_CONFIRMED_INFO:
      return {
        ...state,
        lastConfirmedDetails: {
          ...action.data
        }
      };

    case InforAction.RESET_EXTRACTED_INFO:
      return {
        ...state,
        extracted: null
      };

    case InforAction.RESET_CONFIRMED_INFO:
      return {
        ...state,
        lastConfirmedDetails: null
      };

    case InforAction.RESET_OTHER_INFO:
      return {
        ...state,
        otherDetails: null
      };

    default:
      return state;
  }
}
