import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Page from '@lib/components/v2/Page';
import Animation, { VOI_V2_ANIMATION_FILE_URLS } from '@components/Animation';
import { localizedString } from '@languages';
import classes from './CaptureTips.style.module.scss';

export default class CaptureTips extends Component {
  static propTypes = {
    type: PropTypes.string,
    onHide: PropTypes.func.isRequired,
    onCaptureAgain: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      step: 0
    };
  }

  render() {
    const { type, onHide, onCaptureAgain } = this.props;
    let { step } = this.state;
    const tipTitle = localizedString('captureTips');
    const tipDescription = localizedString(`captureTipsDescription${type}${step + 1}`);

    const buttons = [
      {
        label: localizedString('back'),
        variant: 'transparent',
        onClick: () => {
          if (step === 0) {
            onHide();
          } else {
            this.setState({ step: step - 1 });
          }
        },
        dataTestId: 'capturTips-baclBtn'
      },
      {
        label: step === 2 ? localizedString('captureMyID') : localizedString('next'),
        onClick: () => {
          if (step === 2) {
            step = 0;
            onCaptureAgain();
          } else {
            this.setState({ step: step + 1 });
          }
        },
        dataTestId: 'capturTips-nxtBtn'
      }
    ];

    return (
      <Page buttons={buttons}>
        <div className={classes.wrapper}>
          <div className={classes.heading} data-testid="capturTips-heading">
            {tipTitle}
          </div>
          <div className={classes.description} data-testid="capturTips-txt1">
            {tipDescription}
          </div>
          <div className={classes['animation-container']} data-testid="capturTips-anime">
            {step === 0 && <Animation animationUrl={VOI_V2_ANIMATION_FILE_URLS.CLOSE_FAR} />}
            {step === 1 && <Animation animationUrl={VOI_V2_ANIMATION_FILE_URLS.LAPTOP} />}
            {step === 2 && <Animation animationUrl={VOI_V2_ANIMATION_FILE_URLS.LAMP} />}
          </div>
        </div>
      </Page>
    );
  }
}
