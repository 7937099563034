import { DOCUMENTS } from '@lib/constants/documents';
import { INPUT_TYPES } from '@lib/constants/inputTypes';
import { getStatesFromCountry } from '@lib/countryUtils';
import { getFormValidation } from '@lib/validations/vefifyDetailsFormValidations';
import { localizedString } from '@languages';

export const getFieldsValidationConfig = ({
  idType,
  countryOfIssue,
  values,
  flowType,
  cardType
}) => {
  const formValidation = getFormValidation({ idType, countryOfIssue, values, flowType, cardType });
  return {
    ...formValidation,
    birthDate: formValidation.dateOfBirth,
    documentNumber: formValidation.cardNumber,
    documentState: {
      isRequired: localizedString('stateRequired')
    },
    countryCode: {
      isRequired: localizedString('countryRequired')
    }
  };
};

export const commonFields = {
  firstName: {
    label: localizedString('firstName'),
    value: '',
    type: INPUT_TYPES.TEXT
  },
  middleName: {
    label: localizedString('middleName'),
    value: '',
    type: INPUT_TYPES.TEXT
  },
  lastName: {
    label: localizedString('surname'),
    value: '',
    type: INPUT_TYPES.TEXT
  },
  birthDate: {
    label: localizedString('dateOfBirth'),
    value: '',
    type: INPUT_TYPES.DATE
  },
  idNumber: {
    label: localizedString('idNumber'),
    value: '',
    type: INPUT_TYPES.TEXT
  },
  expiryDate: {
    label: localizedString('dateOfExpiry'),
    value: '',
    type: INPUT_TYPES.DATE
  }
};

export const additionalFieldsPerCardType = {
  [DOCUMENTS.DRIVER_LICENCE.documentType]: {
    documentNumber: {
      label: localizedString('cardNumber'),
      value: '',
      type: INPUT_TYPES.TEXT
    },
    documentState: {
      label: localizedString('state'),
      value: '',
      type: INPUT_TYPES.DROPDOWN,
      options: getStatesFromCountry('AU')
    }
  }
};

export const optionalCountryField = {
  countryCode: {
    label: localizedString('country'),
    value: '',
    type: INPUT_TYPES.DROPDOWN,
    isCountryList: true
  }
};
