import { useState, useRef, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Input } from '@lib/components/v2/Form';
import { Validation } from 'calidation';
import { localizedString } from '@languages';
import { ADDRESS_SEARCH_DEBOUNCED_DELAY_IN_MS } from '@lib/constants/addressSearchDebouncedDelay';
import Address from '@js/services/Address';
import { AddressList } from './AddressList';
import { short } from './AddressFinder.form';
import classes from './AddressFinder.style.module.scss';

const STATUS = {
  IDDLE: 'IDDLE',
  LOADING_LIST_OPTIONS: 'LOADING_LIST_OPTIONS',
  LIST_OPTIONS_LOADED: 'LIST_OPTIONS_LOADED',
  ERROR_GETTING_LIST_OPTIONS: 'ERROR_GETTING_LIST_OPTIONS',
  VALIDATING_ADDRESS: 'VALIDATING_ADDRESS',
  LOADING_FIRST_MATCH: 'LOADING_FIRST_MATCH',
  ERROR_GETTING_FIRST_MATCH: 'ERROR_GETTING_FIRST_MATCH'
};

const MIN_SEARCH_TEXT_LENGTH = 4;
const COUNTRIES_TO_PARSE_NATIVE_ADDRESS = [
  { countryCodeIso2: 'JP', fullAddressOutputDelimiter: '|' }
];

export const AutocompleteAddress = ({
  initialAddress,
  initialNativeAddress,
  countryCodeIso2,
  onChange,
  disabled,
  isManualOptionOn,
  onClickManualOption,
  dataTestId
}) => {
  const initialAddressToValidate = initialAddress || initialNativeAddress;
  const addressListPanelBottomRef = useRef();
  const setFieldRef = useRef();
  const [homeAddress, setHomeAddress] = useState(initialAddressToValidate);

  const [status, setStatus] = useState(STATUS.IDDLE);
  const [addressList, setAddressList] = useState();

  useEffect(() => {
    if (initialAddressToValidate) {
      const isNativeAddress = !initialAddress && !!initialNativeAddress;
      fetchAndSelectFirstMatch(initialAddressToValidate, isNativeAddress);
    }
  }, []);

  const debouncedFetchAddressOptions = useMemo(
    () => debounce(fetchAddressOptions, ADDRESS_SEARCH_DEBOUNCED_DELAY_IN_MS),
    []
  );

  return (
    <div className={classes.inputWrapper}>
      <Validation config={short} initialValues={{ homeAddress, isMatch: false }}>
        {({ setField, errors }) => {
          setFieldRef.current = setField;

          if ([STATUS.VALIDATING_ADDRESS, STATUS.LOADING_FIRST_MATCH].includes(status)) {
            return (
              <div className={classes.validatingAddress}>
                {localizedString('addressFinder.validating')}
              </div>
            );
          }

          return (
            <Input
              type="textarea"
              id="homeAddress"
              required
              label={localizedString('residentalAddress')}
              placeholder={localizedString(
                'addressFinder.FLOW_V2_VERIFY_DETAILS_ADDRESS_PLACEHOLDER'
              )}
              hasError={errors.homeAddress || errors.isMatch}
              onChange={(value) => {
                setHomeAddress(value);
                setField({ isMatch: false });
                setStatus(STATUS.IDDLE);
                if (value && value.trim() !== (homeAddress || '').trim()) {
                  debouncedFetchAddressOptions(value);
                }
              }}
              value={homeAddress}
              className={classes.inputElement}
              dataTestId={dataTestId}
              disabled={disabled}
            />
          );
        }}
      </Validation>
      <span className={classes.icon}>
        <img alt="search" src="images/icons/png/search.png" />
      </span>

      {[
        STATUS.LOADING_LIST_OPTIONS,
        STATUS.LIST_OPTIONS_LOADED,
        STATUS.ERROR_GETTING_LIST_OPTIONS
      ].includes(status) && (
        <AddressList
          loading={status === STATUS.LOADING_LIST_OPTIONS}
          hasError={status === STATUS.ERROR_GETTING_LIST_OPTIONS}
          onSelect={(address, globalAddressKey) => {
            setAddressList(null);
            handleSelect({ address, globalAddressKey });
          }}
          isManualOptionOn={isManualOptionOn}
          onClickManualOption={onClickManualOption}
          data={addressList}
        />
      )}

      <div ref={addressListPanelBottomRef} />
    </div>
  );

  async function fetchAndSelectFirstMatch(value, isNative) {
    setStatus(STATUS.LOADING_FIRST_MATCH);
    const parsingOptions = COUNTRIES_TO_PARSE_NATIVE_ADDRESS.find(
      (item) => item.countryCodeIso2 === countryCodeIso2
    );
    const enableTransliteration = isNative && !!parsingOptions;

    try {
      let parseValue = value;
      if (enableTransliteration) {
        parseValue = await Address.parseNative(
          value,
          countryCodeIso2,
          parsingOptions.fullAddressOutputDelimiter
        );
      }

      const addresses = await Address.find(parseValue, countryCodeIso2, enableTransliteration);
      setStatus(STATUS.IDDLE);
      if (addresses?.[0]) {
        const { text, globalAddressKey } = addresses[0];
        await handleSelect({ address: text, globalAddressKey });
      }
    } catch (error) {
      console.error(error);
      setHomeAddress('');
      setFieldRef.current({ isMatch: false, homeAddress: '' });
      setStatus(STATUS.ERROR_GETTING_FIRST_MATCH);
    }
  }

  async function fetchAddressOptions(value = '') {
    const trimmedValue = value.trim();
    if (trimmedValue && trimmedValue.length < MIN_SEARCH_TEXT_LENGTH) {
      return;
    }

    setStatus(STATUS.LOADING_LIST_OPTIONS);

    try {
      const addresses = await Address.find(trimmedValue, countryCodeIso2);
      setAddressList(addresses);
      setStatus(STATUS.LIST_OPTIONS_LOADED);

      if (addressListPanelBottomRef.current) {
        addressListPanelBottomRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      setAddressList([]);
      setStatus(STATUS.ERROR_GETTING_LIST_OPTIONS);
    }
  }

  async function handleSelect({ address, globalAddressKey }) {
    setStatus(STATUS.VALIDATING_ADDRESS);

    setHomeAddress(address);

    const detailedAddress = await Address.verify({
      address,
      country: countryCodeIso2,
      idHash: globalAddressKey
    });

    setHomeAddress(detailedAddress.homeAddress);
    setStatus(STATUS.IDDLE);
    setFieldRef.current({ isMatch: true });
    onChange({
      isMatch: true,
      ...detailedAddress
    });
  }
};

AutocompleteAddress.propTypes = {
  initialAddress: PropTypes.string,
  initialNativeAddress: PropTypes.string,
  countryCodeIso2: PropTypes.string,
  dataTestId: PropTypes.string,
  onClickManualOption: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isManualOptionOn: PropTypes.bool
};
