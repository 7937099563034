import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Input } from '@lib/components/v2/Form';
import ReactDatePicker from 'react-mobile-datepicker';
import {
  isAndroidDevice,
  dateToString,
  monthYearToString,
  reverseDateFormatSF,
  convertDateToUTC
} from '@lib/Utils';
import { localizedString } from '@languages';
import classes from './DatePicker.style.module.scss';

/**
 * A wrapper around `react-mobile-datepicker` with `Input` component.
 */
export default class DatePicker extends Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    hasError: PropTypes.string,
    disabled: PropTypes.bool,
    displayedDateFormat: PropTypes.string
  };

  static defaultProps = {
    className: '',
    value: '',
    name: '',
    id: '',
    label: '',
    onChange: () => null,
    displayedDateFormat: 'DD-MM-YYYY'
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.handleSelect = this.handleSelect.bind(this);

    this.pickerMenus = {
      'DD-MM-YYYY': ['D', 'M', 'YYYY'],
      'MM-DD-YYYY': ['M', 'D', 'YYYY'],
      'MM-YYYY': ['M', 'YYYY']
    };
  }

  /**
   * Handle the datepicker select
   * @param {Date} date
   * @return {Void}
   */
  handleSelect(date) {
    const { onChange, displayedDateFormat } = this.props;
    const showMonthDateOnly = displayedDateFormat === 'MM-YYYY';
    const value = showMonthDateOnly ? monthYearToString(date) : dateToString(date, false);
    this.setState({ isOpen: false });
    onChange(value);
  }

  // eslint-disable-next-line class-methods-use-this
  getUtcDateValue = (value) => {
    return convertDateToUTC(reverseDateFormatSF(value, true));
  };

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const { isOpen } = this.state;
    const {
      value,
      className,
      onChange,
      hasError,
      disabled = false,
      displayedDateFormat,
      ...restProps
    } = this.props;
    const theme = isAndroidDevice() ? 'android' : 'ios';
    const momentValue = moment(this.getUtcDateValue(value));
    const datePickerProps = {
      theme,
      isOpen,
      confirmText: localizedString('select'),
      cancelText: localizedString('cancel'),
      showFormat: displayedDateFormat,
      min: new Date(1900, 1, 1),
      value: value && momentValue.isValid() ? this.getUtcDateValue(value) : undefined
    };

    const inputDateValue =
      value && momentValue.isValid() ? momentValue.format(displayedDateFormat) : undefined;

    const dateConfig = this.pickerMenus[displayedDateFormat];

    return (
      <div className={classes.wrapper}>
        <div className={className} onClick={() => !disabled && this.setState({ isOpen: true })}>
          <Input
            paddingLeft30
            readOnly
            hasError={hasError}
            value={inputDateValue}
            disabled={disabled}
            {...restProps}
          />
        </div>
        {!disabled && (
          <React.Fragment>
            <ReactDatePicker
              dateFormat={dateConfig}
              onSelect={this.handleSelect}
              onCancel={() => this.setState({ isOpen: false })}
              {...datePickerProps}
            />
            <span onClick={() => this.setState({ isOpen: true })} className={classes.icon}>
              <img alt="" src="images/icons/png/datepicker.png" />
            </span>
          </React.Fragment>
        )}
      </div>
    );
  }
}
