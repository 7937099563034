import React from 'react';
import { isAndroidDevice } from '@lib/Utils';
import { localizedString } from '@languages';
import Message from '@lib/components/v2/Message';
import { SHOW_ENABLE_CAMERA_GRAPHIC } from '@spotMobileConfig';

/**
 * Enable location
 */
export const EnableLocation = (props) => {
  return (
    <Message title="Enable location" {...props} issue>
      {localizedString('weRequireLocationPermission')}
    </Message>
  );
};

/**
 * Enable location settings
 */
export const LocationSettings = (props) => {
  const Chrome = (
    <ol className="oli-style-success">
      <li>{localizedString('gotoChromeSettings')}</li>
      <li>{localizedString('tapOnSiteSettings')}</li>
      <li>{localizedString('tapOnLocation')}</li>
      <li>
        {localizedString('searchFor')}
        <b>{document.location.href}</b>
        {localizedString('searchForEnd')}
      </li>
      <li>{localizedString('makeSureLocationAccessIsAllowed')}</li>
      <li>
        {localizedString('comeBackAndPress')}
        <b>{localizedString('checkAgain')}</b>
        {localizedString('toContinue')}
      </li>
    </ol>
  );
  return (
    <Message title={localizedString('yourSettingsPreventAccessingLocation')} {...props} issue>
      {!isAndroidDevice() ? (
        <div>
          <ol className="oli-style-success">
            <li>{localizedString('gotoYourSettings')}</li>
            <li>{localizedString('tapOnPrivacy')}</li>
            <li>{localizedString('tapOnLocationServices')}</li>
            <li>
              {localizedString('makeSure')}
              <b>{localizedString('locationServices')}</b>
              {localizedString('isSwitchedOn')}
            </li>
            <li>
              {localizedString('scrollDownAndTapOn')}
              <b>{localizedString('safariWebsite')}</b>
            </li>
            <li>
              {localizedString('makeSureItIsSetTo')}
              <b>{localizedString('whileUsingTheApp')}</b>
              {localizedString('makeSureItIsSetToEnd')}
            </li>
            <li>
              {localizedString('comeBackAndPress')}
              <b>{localizedString('checkAgainToContinue')}</b>
            </li>
          </ol>
          <div>
            <b>{localizedString('note')}</b>
            {localizedString('ifSafariWebsitesAlready')}
            <b>{localizedString('whileUsingTheApp2')}</b>
            {localizedString('pleaseResetLocationPrivacySettings')}
          </div>
        </div>
      ) : (
        Chrome
      )}
    </Message>
  );
};

/**
 * If card blurry or crop etc.
 */
export const Permission = (props) => {
  return (
    <Message
      title={localizedString('faceVerification.FLOW_V2_ENABLE_CAMERA_ALERT_TITLE')}
      {...props}
      issue="true"
    >
      <div className="b-enable-exception">
        {SHOW_ENABLE_CAMERA_GRAPHIC && (
          <div className="image">
            <img
              alt=""
              width="100%"
              src={`images/exceptions/enable-camera-${isAndroidDevice() ? 'android' : 'ios'}.png`}
            />
          </div>
        )}
        <h3 className="b-title">
          {localizedString('faceVerification.FLOW_V2_ENABLE_CAMERA_ALERT_DESCRIPTION')}
        </h3>
      </div>
    </Message>
  );
};

/**
 * Enable camera settings
 */
export const CameraSettings = (props) => {
  const Chrome = (
    <div>
      <p style={{ textAlign: 'left' }}>{localizedString('forMostAndroids')}</p>
      <ol>
        <li>
          {localizedString('gotoYour')}
          <b>{localizedString('settings')}</b>
          {localizedString('appsChromePermissions')}
          {localizedString('gotoYourEnd')}
        </li>
        <li>{localizedString('turnOnCamera')}</li>
        <li>{localizedString('comeBackAndPressTryAgain')}</li>
      </ol>
      <br />
      <p style={{ textAlign: 'left' }}>{localizedString('ifStillNotWorkPleaseFollow')}</p>
      <ol>
        <li>
          {localizedString('inYour')}
          <b>{localizedString('browserMenu')}</b>
          {localizedString('gotoSiteSettingsCameraBlocked')}
        </li>
        <li>{localizedString('tapOnBlockedSiteAndTapClearReset')}</li>
        <li>{localizedString('comeBackAndPressTryAgain')}</li>
      </ol>
    </div>
  );
  return (
    <Message
      title={localizedString('faceVerification.FLOW_V2_CAMERA_SETTINGS_ALERT_TITLE')}
      {...props}
      issue
    >
      {!isAndroidDevice() ? (
        <div>
          <ol>
            <li>
              {localizedString('goTo')}
              <b>{localizedString('settings')}</b>
              {localizedString('safari')}
              {localizedString('goToEnd')}
            </li>
            <li>{localizedString('turnOnCameraMicAccess')}</li>
            <li>{localizedString('comeBackAndPressTryAgain')}</li>
          </ol>
        </div>
      ) : (
        Chrome
      )}
    </Message>
  );
};

/**
 * Retry with alternative flow because of permission issue.
 */
export const PermissionIssueRequiresAlternativeFlow = (props) => {
  return (
    <Message
      title={localizedString('faceVerification.FLOW_V2_ENABLE_CAMERA_ALERT_TITLE')}
      {...props}
      issue
    >
      {localizedString('clickTryAgainBelowToProceed')}
    </Message>
  );
};

/**
 * Retry with alternative flow because of camera specification
 */
export const CamSpecIssueRequiresAlternativeFlow = (props) => {
  return (
    <Message title={localizedString('notEnoughCameraSpec')} {...props} issue>
      {localizedString('clickTryAgainBelowToProceed')}
    </Message>
  );
};
