import { SET_IP_COUNTRY_CODE } from '../actions/ipInfo';

const initialState = {
  ipCountryCode: null
};

export const ipInfoReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_IP_COUNTRY_CODE:
      return {
        ...state,
        ipCountryCode: action.data
      };

    default:
      return state;
  }
};
