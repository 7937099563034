import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Page from '@lib/components/v2/Page';
import classNames from 'classnames';
import { localizedString } from '@languages';
import Animation, { VOI_V2_ANIMATION_FILE_URLS } from '@components/Animation';
import parse from 'html-react-parser';

import {
  VOI_FLOW_V2_NAME_MATCH_SHOW_SKIP_THIS_STEP,
  VOI_FLOW_V2_NAME_MATCH_DOCS
} from '@spotMobileConfig';

import { LoadingBar } from '../../components';
import { CaptureTips } from '../../components/Contents';
import classes from './NameMatch.style.module.scss';

export const defaultNameMatchDocs = [
  {
    type: 'AUS_AUTO_NAME',
    title: 'Change of name certificate',
    checked: true,
    ocr: false,
    nextAfterOcr: { step: 2 }
  },
  {
    type: 'AUS_AUTO_MARRIAGE',
    title: 'Marriage certificate',
    checked: false,
    ocr: false,
    nextAfterOcr: { step: 2 }
    // nextAfterOcr: {showCaptureExtra: true}
  }
];
class NameMatch extends Component {
  static propTypes = {
    onGoBack: PropTypes.func,
    onNextStep: PropTypes.func,
    frontParams: PropTypes.object,
    flowType: PropTypes.string,
    backOfCard: PropTypes.bool,
    onUpdateSelectedDocList: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.element = null;

    const optionList = VOI_FLOW_V2_NAME_MATCH_DOCS
      ? [...VOI_FLOW_V2_NAME_MATCH_DOCS]
      : [...defaultNameMatchDocs];
    if (VOI_FLOW_V2_NAME_MATCH_SHOW_SKIP_THIS_STEP) {
      optionList.push({
        type: 'SKIP',
        title: 'Skip this step',
        checked: false
      });
    }

    this.state = {
      optionList,
      progressBar: 0,
      isUploading: false,
      isLoading: false,
      error: null,
      showCaptureTips: ''
    };

    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleCapture = this.handleCapture.bind(this);
  }

  /**
   * Go back to the previous step
   * @param {ClickEvent} e
   * @return {Void}
   */
  async handleGoBack(e) {
    const { onGoBack } = this.props;
    e.preventDefault();
    onGoBack();
  }

  async handleCapture(e) {
    if (!e.target.files[0]) {
      return;
    }
    const { optionList } = this.state;
    const imagefile = e.target.files[0];
    e.target.value = '';

    const selected = optionList.find((opt) => {
      return opt.checked;
    });

    if (!selected) {
      console.error('Please select an option');
    }

    const { frontParams, flowType, backOfCard, onUpdateSelectedDocList } = this.props;

    let params;
    if (backOfCard) {
      params = {
        ...frontParams,
        backFile: imagefile,
        flowType,
        ocr: selected.ocr,
        idType: selected.type
      };
    } else {
      params = { frontFile: imagefile, flowType, ocr: selected.ocr, idType: selected.type };
    }

    onUpdateSelectedDocList({ ...selected, params });
  }

  handleNextStep(e) {
    if (e) {
      e.preventDefault();
    }
    const { onNextStep } = this.props;
    const { optionList } = this.state;

    const selectedItem = optionList.find((item) => item.checked);

    if (selectedItem.type === 'SKIP') {
      onNextStep({ step: 2, showNameMatch: false });
    } else {
      this.element.click();
    }
  }

  onOptionClick(item, index) {
    if (item.checked) {
      return;
    }

    const { optionList } = this.state;
    for (let i = 0; i < optionList.length; i++) {
      optionList[i].checked = index === i;
    }
    this.setState({ optionList });
  }

  onCaptureAgain() {
    this.setState({ showCaptureTips: '' });
    this.element.click();
  }

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const { optionList, progressBar, isUploading, isLoading, error, showCaptureTips } = this.state;
    const { component: Error, props: errorProps } = error || {};

    const footerButtons = [
      {
        label: localizedString('back'),
        variant: 'transparent',
        onClick: this.handleGoBack
      }
    ];
    if (isUploading || isLoading) {
      footerButtons.push({
        label:
          isUploading && progressBar < 100
            ? localizedString('uploading')
            : localizedString('loading'),
        variant: 'transparent',
        loading: true
      });
    } else {
      footerButtons.push({
        label: localizedString('continue'),
        type: 'submit',
        onClick: () => this.handleNextStep(),
        dataTestId: 'chngNm-continue'
      });
    }

    return (
      <div>
        <input
          type="file"
          name="image"
          accept="image/*"
          capture="environment"
          onChange={this.handleCapture}
          ref={(ref) => {
            this.element = ref;
          }}
          style={{ opacity: 0, zIindex: 99 }}
        />
        {Error && <Error {...errorProps} />}
        {showCaptureTips !== '' && (
          <CaptureTips
            type={showCaptureTips}
            onHide={() => this.setState({ showCaptureTips: '' })}
            onCaptureAgain={() => this.onCaptureAgain()}
          />
        )}
        {!Error && showCaptureTips === '' && (
          <Page buttons={footerButtons} forceFillViewPort={isLoading || isUploading}>
            {!isLoading && !isUploading && (
              <div className={classes.wrapper}>
                <div className={classes.heading} data-testid="chngNm-heading">
                  {localizedString('app.VOI_FLOW_V2_NAME_MATCH_HEADING')}
                </div>
                <div className={classes.description} data-testid="chngNm-instruction">
                  {parse(localizedString('app.VOI_FLOW_V2_NAME_MATCH_DESCRIPTION'))}
                </div>
                <div className={classes.option_list}>
                  {optionList.map((item, index) => {
                    const iconName = `images/icons/svg/${
                      item.checked ? 'icon_radio_checked.svg' : 'icon_radio_unchecked.svg'
                    }`;

                    return (
                      <div
                        key={item.title}
                        data-testid={`chngNm-docType-${index}`}
                        className={classNames(classes.option_item, item.checked && classes.checked)}
                        onClick={() => this.onOptionClick(item, index)}
                      >
                        <div className={classes.check_mark_wrapper}>
                          <img src={iconName} alt="" />
                        </div>
                        <div className={classes.option_title}>{item.title}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {(isLoading || isUploading) && (
              <div className={classNames(classes.wrapper, classes.fill_view_port)}>
                <div className={classes.heading}>
                  {localizedString('app.VOI_FLOW_V2_NAME_MATCH_LOADING_DETAILS_HEADING')}
                </div>
                <div className={classes.description}>
                  {localizedString('app.VOI_FLOW_V2_NAME_MATCH_LOADING_DETAILS_DESCRIPTION')}
                </div>
                <div className={classes['animation-container']}>
                  <Animation animationUrl={VOI_V2_ANIMATION_FILE_URLS.REVIEW} />
                </div>
                <LoadingBar width={progressBar} />
              </div>
            )}
          </Page>
        )}
      </div>
    );
  }
}

export default NameMatch;
