import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { isIOS } from 'react-device-detect';
import parse from 'html-react-parser';
import Page from '@lib/components/v2/Page';
import { TitlePage } from '@lib/components/v2/TitlePage';
import { DOCUMENTS } from '@js/lib/constants/documents';
import { localizedString } from '@languages';
import classes from './CaptureNSWDigitalDriverLicenceScreen.style.module.scss';

export const CaptureNSWDigitalDriverLicenceScreen = ({
  onCloseScreen = () => {},
  onUploadDigitalImage = () => {}
}) => {
  const inputRef = useRef();

  const footerButtons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: onCloseScreen
    },
    {
      label: localizedString('capture.FLOW_V2_CAPTURE_DIGITAL_UPLOAD_BUTTON_TITLE'),
      type: 'submit',
      onClick: selectDigitalImage
    }
  ];

  return (
    <Page buttons={footerButtons} forceFillViewPort>
      <input
        ref={inputRef}
        onChange={handleFileSelected}
        className={classes.hiddenInput}
        type="file"
        accept="image/*"
      />

      <div style={{ height: '100%' }}>
        <TitlePage text={localizedString('capture.FLOW_V2_CAPTURE_DIGITAL_HEADING')} />

        <div className={classes.description}>{localizedString('plasticCardPreferred')}</div>

        <div className={classes.instructionList}>
          <div className={classes.item}>
            <div className={classes.instructionNumber}>1.</div>
            <div className={classes.instructionText}>{localizedString('goToServiceNsw')}</div>
            <a
              className={classes.linkToSNSW}
              href={isIOS ? 'com.rta.myRTA://' : 'intent://#Intent;package=au.gov.nsw.service;end'}
            >
              {localizedString('openSNSW')}
            </a>
          </div>
          <div className={classes.item}>
            <div className={classes.instructionNumber}>2.</div>
            <div className={classes.instructionText}>{localizedString('takeScreenshot')}</div>
          </div>
          <div className={classes.item}>
            <div className={classes.instructionNumber}>3.</div>
            <div className={classes.instructionText}>
              {parse(localizedString('comeBackToScreenAndUploadScreenshot'))}
            </div>
          </div>
          <div className={classes.item}>
            <div className={classes.instructionNumber}>4.</div>
            <div className={classes.instructionText}>
              {parse(localizedString('youWillNeedToEnterManually'))}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );

  function selectDigitalImage() {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  }

  function handleFileSelected(e) {
    const imageFile = e.target.files[0];
    if (!imageFile) {
      return;
    }

    onUploadDigitalImage({ imageFile, idType: DOCUMENTS.DIGITAL_DRIVER_LICENCE.documentType });
  }
};

CaptureNSWDigitalDriverLicenceScreen.propTypes = {
  onCloseScreen: PropTypes.func,
  onUploadDigitalImage: PropTypes.func
};
