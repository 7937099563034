import axios from 'axios';
import { useState, useEffect } from 'react';

export function useAnimation(animationUrl) {
  const [animationData, setAnimationData] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    if (animationUrl) {
      fetchData();
    }
  }, [animationUrl]);

  async function fetchData() {
    try {
      const response = await axios.get(animationUrl);
      if (response?.data?.status === 'error') {
        throw new Error(response?.data?.message || 'unexpected error');
      }
      setAnimationData(response.data);
    } catch (reason) {
      setError(reason);
    }
  }

  return {
    animationData,
    error
  };
}
