export default class Information {
  static SET_EXTRACTED_INFO = 'SET_EXTRACTED_INFO';

  static RESET_EXTRACTED_INFO = 'RESET_EXTRACTED_INFO';

  static SET_CONFIRMED_INFO = 'SET_CONFIRMED_INFO';

  static RESET_CONFIRMED_INFO = 'RESET_CONFIRMED_INFO';

  static SET_OTHER_INFO = 'SET_OTHER_INFO';

  static RESET_OTHER_INFO = 'RESET_OTHER_INFO';

  static setExtractedInfo(data) {
    return {
      type: this.SET_EXTRACTED_INFO,
      data
    };
  }

  static setConfirmedInfo(data) {
    return {
      type: this.SET_CONFIRMED_INFO,
      data
    };
  }

  static resetExtractedInfo() {
    return {
      type: this.RESET_EXTRACTED_INFO
    };
  }

  static resetConfirmedInfo() {
    return {
      type: this.RESET_CONFIRMED_INFO
    };
  }

  /**
   * Return an action to update the identify details
   * @param {Object} data
   */
  static setOtherIdInfo(data) {
    return {
      type: this.SET_OTHER_INFO,
      data
    };
  }

  /**
   * Return an action to reset the identify details
   * @param {Object} data
   */
  static resetOtherIdInfo(data) {
    return {
      type: this.RESET_OTHER_INFO,
      data
    };
  }
}
