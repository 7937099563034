import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import classnames from 'classnames';
import APIs from '@services/APIs';
import Header from '@lib/components/v2/Page/Header';
import { localizedString, isLocalizedStringDefined } from '@languages';
import { useLogo } from '@js/hooks/useLogo';
import { SUCCESS_SCREEN_HIDE_HEADER, SUCCESS_ANIMATION_SIZE_SCALE } from '@spotMobileConfig';
import './Success.style.scss';
import Animation, { FLOW_V2_ANIMATION_FILE_URLS } from '@components/Animation';

const Success = ({ successMessage, redirect = false }) => {
  document.body.style.background = '#fff';

  const finalLogo = useLogo({ isSuccessScreen: true });

  const handleRedirect = () => {
    if (redirect) {
      APIs.return();
    }
  };

  let successMessageTitle = parse(localizedString('verificationComplete.title'));

  if (redirect && isLocalizedStringDefined('verificationComplete.titleRedirect')) {
    successMessageTitle = parse(localizedString('verificationComplete.titleRedirect'));
  }

  if (successMessage) {
    successMessageTitle = parse(successMessage);
  }

  return (
    <div className="success-flowv2-screen">
      <div>
        {!SUCCESS_SCREEN_HIDE_HEADER && (
          <div className="feedback-link-container-success">
            <Header hideLogo />
          </div>
        )}

        <div className="wrapper">
          {!SUCCESS_SCREEN_HIDE_HEADER && (
            <>
              <div className="img-container" data-testid="cmplt-logo">
                <img alt="" src={finalLogo} />
              </div>

              <div className={classnames({ heading: !successMessage })} data-testid="cmplt-heading">
                {successMessageTitle}
              </div>
              <div className="title" onClick={handleRedirect} data-testid="cmplt-instruction">
                {redirect
                  ? parse(localizedString('verificationComplete.redirectedDescription'))
                  : parse(localizedString('verificationComplete.youCanCloseThisWindow'))}
              </div>
            </>
          )}

          {FLOW_V2_ANIMATION_FILE_URLS.SUCCESS && (
            <div className="animation-container" data-testid="cmplt-animation">
              <Animation
                scale={SUCCESS_ANIMATION_SIZE_SCALE}
                animationUrl={FLOW_V2_ANIMATION_FILE_URLS.SUCCESS}
              />
            </div>
          )}

          {SUCCESS_SCREEN_HIDE_HEADER && <div className="footer">{successMessageTitle}</div>}
        </div>
      </div>
    </div>
  );
};
Success.propTypes = {
  successMessage: PropTypes.any,
  redirect: PropTypes.bool
};
export default Success;
